import React, { type FC } from 'react';

import { useAppSelector } from '../../../../hooks/redux';
import LogoIcon from '../../../icons/LogoIcon.svg';
import { AddFundsIcon } from '../../../icons/AddFundsIcon';
import { FabComponent } from '../../../../components';

import style from '../HeaderLiveChatComponent.module.css';

interface IMobileHeaderLiveChatComponent {
  setChatOpen: (el: boolean) => void
  setOpenDeposit: (el: boolean) => void
}

export const MobileHeaderLiveChatComponent: FC<IMobileHeaderLiveChatComponent> = ({ setChatOpen, setOpenDeposit = () => {} }) => {
  const { firstName } = useAppSelector((state) => state.user);

  const depositBtn = (): void => {
    setOpenDeposit(true);
    setChatOpen(false)
  }

  return (
    <>
    <div className={ style.headerLiveMobileChat }>
        <div className={ style.headerLiveMobileLogo }>
          <img src={LogoIcon} alt="LogoIcon" />
        </div>

        <div className={ style.headerLiveMobileOptions }>
          <div className={ style.headerLiveMobileBtn }>
            <FabComponent variant="extended" size='small'
            onClick={ depositBtn }
            >
              <AddFundsIcon color='var(--CFD-theme-System-Primary)'/>
              <span>Deposit</span>
            </FabComponent>
          </div>
          {
            (firstName !== undefined && firstName.length > 0) && (
              <div className={ style.headerLiveMobileUserIcon }>
                { firstName[0] }
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}
